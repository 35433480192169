import { AppDispatch, AppThunk, State } from 'src/store/model'

import { logEventPlay } from 'src/utils'
import { HANDLE_STATISTICS_DELAY } from 'src/constants'

import { selectFilteredStations, selectCurrentStationIndex, selectStationsBaseData } from '../data/selectors'

import { setSelectedStationId, updateStatisticsForStation } from '../user/slice'
import { selectSelectedStationId } from '../user/selectors'

import { setLoading, setPlaying } from './slice'
import { selectPlaying } from './selectors'

let handleStatisticsTimerId: number | null = null

export const handleStatistics = (id: string): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  if (handleStatisticsTimerId) clearTimeout(handleStatisticsTimerId)
  // @ts-ignore
  handleStatisticsTimerId = setTimeout(() => {
    logEventPlay(selectStationsBaseData(getState()).find((s): boolean => s.id === id) || null)
    dispatch(updateStatisticsForStation(id))
  }, HANDLE_STATISTICS_DELAY)
}

export const setStation = (id: string): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  const currentStationId = selectSelectedStationId(getState())
  if (currentStationId !== id) {
    dispatch(setLoading(true))
    dispatch(setSelectedStationId(id))
    dispatch(handleStatistics(id))
  }
}

export const toNextStation = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  const filteredStations = selectFilteredStations(getState())
  if (filteredStations.length) {
    const currentStationIndex = selectCurrentStationIndex(getState())
    const nextIndex = currentStationIndex + 1
    const maxIndex = filteredStations.length - 1
    const nextStation = filteredStations[nextIndex > maxIndex ? 0 : nextIndex]
    if (nextStation) dispatch(setStation(nextStation.id))
  }
}

export const toPreviousStation = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  const filteredStations = selectFilteredStations(getState())
  if (filteredStations.length) {
    const currentStationIndex = selectCurrentStationIndex(getState())
    const prevIndex = currentStationIndex - 1
    const maxIndex = filteredStations.length - 1
    const prevStation = filteredStations[prevIndex < 0 ? maxIndex : prevIndex]
    if (prevStation) dispatch(setStation(prevStation.id))
  }
}

export const togglePlayback = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  const playing = selectPlaying(getState())
  const currentStationId = selectSelectedStationId(getState())
  dispatch(setPlaying(!playing))
  if (!playing && currentStationId) {
    dispatch(handleStatistics(currentStationId))
  }
}
