import { DisplayMode } from './model'

// eslint-disable-next-line import/prefer-default-export
export const getNextDisplayMode = (displayMode: DisplayMode): DisplayMode => {
  // Poor man's implementation. TODO: Should someday be able to get next enum value
  switch (displayMode) {
    case DisplayMode.GRID2:
      return DisplayMode.GRID1
    case DisplayMode.LIST:
      return DisplayMode.GRID2
    case DisplayMode.GRID1:
    default:
      return DisplayMode.LIST
  }
}
