import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BootStrapKey, DataState, Features, MetaData, StationBase } from './model'

export const initialState: DataState = { boot: null, stations: [], metadata: [], subscribed: false, features: {} }

export const slice = createSlice({
  name: '@data',
  initialState,
  reducers: {
    setBootStrapKey: (state: DataState, action: PayloadAction<BootStrapKey>) => {
      state.boot = action.payload
    },
    setStations: (state: DataState, action: PayloadAction<StationBase[]>) => {
      state.stations = action.payload
    },
    setMetadata: (state: DataState, action: PayloadAction<MetaData[]>) => {
      state.metadata = action.payload
    },
    setSubscribed: (state: DataState, action: PayloadAction<boolean>) => {
      state.subscribed = action.payload
    },
    setFeatures: (state: DataState, action: PayloadAction<Features>) => {
      state.features = action.payload
    }
  }
})

export const { setBootStrapKey, setStations, setMetadata, setSubscribed, setFeatures } = slice.actions

export default slice.reducer
