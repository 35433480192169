import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from './model'

export const initialState: UiState = { fetching: false, playing: false, loading: false, historyStationId: null }

export const slice = createSlice({
  name: '@ui',
  initialState,
  reducers: {
    setFetching: (state: UiState, action: PayloadAction<boolean>) => {
      state.fetching = action.payload
    },
    setPlaying: (state: UiState, action: PayloadAction<boolean>) => {
      if (state.playing !== action.payload && !state.playing) {
        state.loading = true
      }
      state.playing = action.payload
    },
    setLoading: (state: UiState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setHistoryStationId: (state: UiState, action: PayloadAction<string | null>) => {
      state.historyStationId = action.payload
    }
  }
})

export const { setFetching, setPlaying, setLoading, setHistoryStationId } = slice.actions

export default slice.reducer
