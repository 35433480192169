import React from 'react'
import styled, { withTheme } from 'styled-components'

import { Icon, WithTheme } from 'src/types'

interface Props extends WithTheme, Icon {}

const SpinningSvg = styled.svg`
  animation: spin 900ms linear infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingIcon: React.FC<Props> = ({ theme, size = 2.5, fill = theme.colors.control }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <SpinningSvg width={width} height={height} viewBox="0 0 192 192">
      <path
        d="M96 144c6.627 0 12 5.373 12 12s-5.373 12-12 12-12-5.373-12-12 5.373-12 12-12zm-52.326-15.473c5.467-5.467 14.332-5.467 19.8 0 5.466 5.467 5.466 14.332 0 19.799-5.468 5.467-14.333 5.467-19.8 0-5.467-5.467-5.467-14.332 0-19.8zm87.681 2.828c3.906-3.905 10.237-3.905 14.142 0 3.906 3.906 3.906 10.237 0 14.142-3.905 3.906-10.236 3.906-14.142 0-3.905-3.905-3.905-10.236 0-14.142zM36 80c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16zm120 8a8 8 0 110 16 8 8 0 010-16zM40.846 40.846c7.03-7.03 18.426-7.03 25.456 0 7.029 7.03 7.029 18.426 0 25.456-7.03 7.029-18.427 7.029-25.456 0-7.03-7.03-7.03-18.427 0-25.456zm93.338 8.485a6 6 0 118.485 8.485 6 6 0 01-8.485-8.485zM96 16c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20 8.954-20 20-20z"
        fill={fill}
      />
    </SpinningSvg>
  )
}

export default withTheme(LoadingIcon)
