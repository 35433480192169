import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Property } from 'csstype'
import Truncate from 'react-truncate'

import { IconState, WithTheme } from 'src/types'
import { EVENT_CLOSE_MODALS, GRID_THUMB_BASE_URL } from 'src/constants'
import { dimensions } from 'src/styles'
import { FavoriteIcon, PlaylistIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

import { selectFeatureHistory, Station } from 'src/store/data'
import { toggleFavorite } from 'src/store/user'
import { setStation, setPlaying, setHistoryStationId } from 'src/store/ui'

const paddingSize = 0.4375

interface Props extends WithTheme {
  station: Station
  selected: boolean
}

const StationGridItem: React.FC<Props> = ({ theme, station, selected }) => {
  const dispatch = useDispatch()
  const featureHistoryEnabled = useSelector(selectFeatureHistory)

  const { id, name, image, favorite, metadata } = station
  const imageUri = `${GRID_THUMB_BASE_URL}/${image}`

  const iconState = favorite ? IconState.active : IconState.normal

  const favoriteColor = theme.colors.station.favoriteControl[iconState]

  const onClickItem = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(setStation(id))
    dispatch(setPlaying(true))
  }

  const onClickFavorite = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toggleFavorite(id))
  }

  const onClickHistory = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(setHistoryStationId(id))
  }

  const text = (metadata && metadata.title) || name

  return (
    <Container onClick={onClickItem} selected={selected}>
      <StationItemImage imageUri={imageUri} selected={selected} />

      <FavoriteControl onClick={onClickFavorite}>
        <FavoriteIcon fill={favoriteColor} />
      </FavoriteControl>

      {featureHistoryEnabled && (
        <HistoryControl onClick={onClickHistory}>
          <PlaylistIcon fill={theme.colors.station.historyIcon} />
        </HistoryControl>
      )}

      <TextContainer>
        <Truncate lines={2}>{text}</Truncate>
      </TextContainer>
    </Container>
  )
}

export default withTheme(StationGridItem)

const Container = styled.div<{ selected: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: ${paddingSize}rem;
  padding-right: ${paddingSize}rem;
  padding-left: ${paddingSize}rem;
  background-color: ${({ theme, selected }): Property.Color => (selected ? theme.colors.selectedBackground : theme.colors.mainBackground)};
  cursor: pointer;
  position: relative;
`

const StationItemImage = styled.div<{ imageUri: string; selected: boolean }>`
  overflow: hidden;
  height: 0;
  width: 100%;
  padding-top: 56.25%; // 400 / 225
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ theme }): Property.Color => theme.colors.mainBackground};
  background-image: url(${({ imageUri }): string => imageUri});
  opacity: ${({ theme, selected }): number => (selected ? theme.station.item.selected.image.opacity : theme.station.item.image.opacity)};
  border-radius: 0.1rem;
`

const FavoriteControl = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: ${dimensions.paddingHorizontal}rem;
  padding-top: ${dimensions.paddingHorizontal - paddingSize}rem;
  width: ${dimensions.iconSize}rem;
  height: ${dimensions.iconSize}rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const HistoryControl = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  left: ${dimensions.paddingHorizontal}rem;
  padding-top: ${dimensions.paddingHorizontal - paddingSize}rem;
  width: ${dimensions.iconSize}rem;
  height: ${dimensions.iconSize}rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 0.3125rem 0;
  font-size: 0.9rem;
  height: 2.75rem;
  color: ${({ theme }): Property.Color => theme.colors.station.meta};
  overflow: hidden;
  user-select: none;
`
