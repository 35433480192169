import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import isElectron from 'is-electron'

import { APP_SET_PLAY, APP_STATION_NEXT, APP_STATION_PREV, APP_TOGGLE_PLAY } from 'src/constants'
import { togglePlayback, toNextStation, toPreviousStation } from 'src/store/ui/thunks'
import { setPlaying } from '../store/ui/slice'

const ElectronHandler: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // @ts-ignore
    if (isElectron() && window.ipcRenderer) {
      const onSetPlayEvent = (event: Event, value: boolean): void => {
        dispatch(setPlaying(value))
      }
      // @ts-ignore
      const { ipcRenderer: ipc } = window
      ipc.on(APP_SET_PLAY, onSetPlayEvent)
      ipc.on(APP_TOGGLE_PLAY, dispatch(togglePlayback))
      ipc.on(APP_STATION_NEXT, dispatch(toNextStation()))
      ipc.on(APP_STATION_PREV, dispatch(toPreviousStation()))
      return (): void => {
        ipc.removeListener(APP_SET_PLAY, onSetPlayEvent)
        ipc.removeListener(APP_TOGGLE_PLAY, dispatch(togglePlayback))
        ipc.removeListener(APP_STATION_NEXT, dispatch(toNextStation()))
        ipc.removeListener(APP_STATION_PREV, dispatch(toPreviousStation()))
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {}
  }, [togglePlayback, toNextStation, toPreviousStation, setPlaying, dispatch])
  return null
}

export default ElectronHandler
