import React from 'react'
import { useSelector } from 'react-redux'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { selectLocale } from 'src/store/user/selectors'

import translations from './translations'

const IntlProvider: React.FC = ({ children }) => {
  const locale = useSelector(selectLocale)

  return (
    <ReactIntlProvider locale={locale} defaultLocale="nl" messages={translations[locale]}>
      {children}
    </ReactIntlProvider>
  )
}

export default IntlProvider
