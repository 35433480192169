import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import storage from 'src/utils/persist'

import userReducer from './user/slice'
import uiReducer from './ui/slice'
import dataReducer from './data/slice'

const rootReducer = combineReducers({
  user: persistReducer({ key: 'user', storage }, userReducer),
  ui: uiReducer,
  data: dataReducer
})

export default rootReducer
