import { createSelector, Selector } from '@reduxjs/toolkit'

import { State } from 'src/store/model'

import { FilterValue, PoS } from '../user/model'
import { selectFavoriteStationIds, selectFilter, selectSelectedStationId, selectStatistics, selectPointOfSale } from '../user/selectors'
import { selectHistoryStationId } from '../ui/selectors'
import { isMatchForSearchValue } from './utils'
import { BootStrapKey, DataState, Features, MetaData, Station, StationBase } from './model'

export const selectDataSlice: Selector<State, DataState> = (state: State) => state.data

export const selectBootStrapKey: Selector<State, BootStrapKey> = createSelector(selectDataSlice, state => state.boot)

export const selectFeatures: Selector<State, Features> = createSelector(selectDataSlice, state => state.features)

export const selectFeatureHistory: Selector<State, boolean> = createSelector(selectFeatures, state => state.history ?? false)

export const selectStationsBaseData: Selector<State, StationBase[]> = createSelector(selectDataSlice, state => state.stations)

export const selectMetaData: Selector<State, MetaData[]> = createSelector(selectDataSlice, state => state.metadata)

export const selectSubscribed: Selector<State, boolean> = createSelector(selectDataSlice, state => state.subscribed)

export const selectStations: Selector<State, Station[]> = createSelector(
  [selectStationsBaseData, selectMetaData, selectFavoriteStationIds, selectSelectedStationId, selectStatistics],
  (baseStations, metadata, favoriteStationIds, selectedStationId, statistics) =>
    baseStations.map(station => ({
      ...station,
      selected: !!selectedStationId && selectedStationId === station.id,
      favorite: favoriteStationIds.includes(station.id),
      metadata: metadata.find((m): boolean => m.id === station.id),
      statistics: statistics && statistics[station.id]
    }))
)

export const selectStationHistoryStation: Selector<State, Station | null> = createSelector(
  [selectStations, selectHistoryStationId],
  (stations, stationId) => stations.find((s): boolean => s.id === stationId) || null
)

export const getSortedStationsForStatistic = (stations: Station[], metric: 'playCount' | 'lastPlayed'): Station[] =>
  stations
    .filter(station => station.statistics)
    .sort((a, b) => ((a.statistics ? a.statistics[metric] : 0) > (b.statistics ? b.statistics[metric] : 0) ? -1 : 1))

export const selectFilteredStations: Selector<State, Station[]> = createSelector(
  [selectStations, selectFavoriteStationIds, selectFilter],
  (stations, favoriteStationIds, filter) => {
    if (!filter) return stations
    switch (filter) {
      case FilterValue.FAVORITES:
        return stations.filter((s): boolean => favoriteStationIds.includes(s.id))
      case FilterValue.MOST_PLAYED:
        return getSortedStationsForStatistic(stations, 'playCount')
      case FilterValue.RECENTLY_PLAYED:
        return getSortedStationsForStatistic(stations, 'lastPlayed')
      default:
        return stations.filter((s): boolean => isMatchForSearchValue(s, filter))
    }
  }
)

export const selectCurrentStationIndex: Selector<State, number> = createSelector(
  [selectFilteredStations, selectSelectedStationId],
  (filteredStations, selectedStationId) =>
    selectedStationId ? filteredStations.findIndex((s: Station): boolean => s.id === selectedStationId) : -1
)

export const selectSelectedStation: Selector<State, Station | undefined> = createSelector(
  [selectStations, selectSelectedStationId],
  (stations, selectedStationId) => stations.find((s: Station): boolean => s.id === selectedStationId)
)

export const selectPath: Selector<State, PoS | BootStrapKey> = createSelector(
  [selectBootStrapKey, selectPointOfSale],
  (bootStrapKey, pointOfSale) => (bootStrapKey === 'default' ? pointOfSale : bootStrapKey)
)
