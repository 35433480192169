import { createStatic } from 'styled-components-breakpoint'

const breakpoints = createStatic({
  mobile: 0,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  largeDesktop: 1600
})

export default breakpoints
