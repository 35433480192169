import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Statistics } from '../data/model'

import { DisplayMode, FilterValue, Locale, PoS, ThemeName, UserState } from './model'
import { getNextDisplayMode } from './utils'

export const initialState: UserState = {
  pos: 'nl',
  locale: 'nl',
  theme: ThemeName.LIGHT,
  appearanceFollowOS: true,
  displayMode: DisplayMode.GRID2,
  selectedStationId: null,
  filter: null,
  statistics: {},
  favoriteStationIds: []
}

export const slice = createSlice({
  name: '@user',
  initialState,
  reducers: {
    setPointOfSale: (state: UserState, action: PayloadAction<PoS>) => {
      state.pos = action.payload
    },
    setLocale: (state: UserState, action: PayloadAction<Locale>) => {
      state.locale = action.payload
    },
    setTheme: (state: UserState, action: PayloadAction<ThemeName>) => {
      state.theme = action.payload
    },
    setAppearanceFollowOS: (state: UserState, action: PayloadAction<boolean>) => {
      state.appearanceFollowOS = action.payload
    },
    setDisplayMode: (state: UserState, action: PayloadAction<DisplayMode>) => {
      state.displayMode = action.payload
    },
    toggleTheme: (state: UserState) => {
      state.theme = state.theme === ThemeName.LIGHT ? ThemeName.DARK : ThemeName.LIGHT
    },
    setSelectedStationId: (state: UserState, action: PayloadAction<string | null>) => {
      state.selectedStationId = action.payload
    },
    setFilter: (state: UserState, action: PayloadAction<FilterValue | string | null>) => {
      if (action.payload === FilterValue.CLEAR_COMMAND) {
        state.filter = null
        state.statistics = {}
        state.favoriteStationIds = []
      } else {
        state.filter = action.payload
      }
    },
    setFavoriteStationIds: (state: UserState, action: PayloadAction<string[]>) => {
      state.favoriteStationIds = action.payload
    },
    setStatistics: (state: UserState, action: PayloadAction<Record<string, Statistics>>) => {
      state.statistics = action.payload
    },
    updateStatisticsForStation: (state: UserState, action: PayloadAction<string>) => {
      const stationId = action.payload
      const playCount = (state.statistics[stationId] ? state.statistics[stationId].playCount : 0) + 1
      const lastPlayed = Date.now()
      state.statistics = {
        ...state.statistics,
        [stationId]: { lastPlayed, playCount }
      }
    },
    toggleFavorite: (state: UserState, action: PayloadAction<string>) => {
      const id = action.payload
      if (state.favoriteStationIds.includes(id)) {
        state.favoriteStationIds = state.favoriteStationIds.filter((value: string): boolean => value !== id)
      } else {
        state.favoriteStationIds = [...state.favoriteStationIds, id]
      }
    },
    toggleFavoritesFilter: (state: UserState) => {
      state.filter = state.filter !== FilterValue.FAVORITES ? FilterValue.FAVORITES : null
    },
    toggleDisplayMode: (state: UserState) => {
      state.displayMode = getNextDisplayMode(state.displayMode)
    }
  }
})

export const {
  setPointOfSale,
  setLocale,
  setTheme,
  setAppearanceFollowOS,
  toggleTheme,
  setSelectedStationId,
  setFilter,
  toggleFavorite,
  toggleFavoritesFilter,
  toggleDisplayMode,
  setStatistics,
  updateStatisticsForStation
} = slice.actions

export default slice.reducer
