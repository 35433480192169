import React from 'react'
import { withTheme } from 'styled-components'

import { Icon, WithTheme } from 'src/types'

interface Props extends WithTheme, Icon {}

const PlayIcon: React.FC<Props> = ({ theme, size = 2.5, fill = theme.colors.control }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <path d="M17.7 9.4c.6.4.6.9 0 1.3L3.3 19.3c-.6.4-1.1.1-1.1-.6V1.3c0-.7.5-1 1.1-.6l14.4 8.7z" fill={fill} />
    </svg>
  )
}

export default withTheme(PlayIcon)
