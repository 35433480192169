import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactHowler from 'react-howler'

import { selectSelectedStation } from 'src/store/data'
import { selectPlaying, setLoading } from 'src/store/ui'

const getProtocolLessStream = (stream: string): string => stream.replace(/^http(s?):\/\//, '//')

export default (): React.ReactElement => {
  const dispatch = useDispatch()
  const selectedStation = useSelector(selectSelectedStation)
  const isPlay = useSelector(selectPlaying)

  if (selectedStation) {
    if (isPlay) {
      const stream = getProtocolLessStream(selectedStation.stream)
      // const stream = `https://stream.zapp.fm/nl/${selectedStation.slug}`
      const onLoad = (): void => {
        dispatch(setLoading(true))
      }

      const onPlay = (): void => {
        dispatch(setLoading(false))
      }

      const onError = (): void => {
        // dispatch(setPlaying(false)) /* consciously not doing this, to prevent 'blinking' behavior */
        // dispatch(setLoading(false))
      }

      return <ReactHowler src={stream} html5 onLoad={onLoad} onPlay={onPlay} onLoadError={onError} />
    }
    setLoading(false)
  }

  return <></>
}
