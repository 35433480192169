import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled, { withTheme, createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components'
import { Property } from 'csstype'
import Div100vh from 'react-div-100vh'

import { WithTheme } from 'src/types'
import { breakpoints } from 'src/styles'
import { logPageview } from 'src/utils'
import { selectSelectedStation } from 'src/store/data'
import HistoryModal from './HistoryModal'

const Main: React.FC<WithTheme> = ({ children }) => {
  const selectedStation = useSelector(selectSelectedStation)

  useEffect(() => logPageview('/'), [])

  return (
    <>
      <GlobalStyle />
      <GridContainer selectedStation={!!selectedStation}>{children}</GridContainer>
      <HistoryModal />
    </>
  )
}

export default withTheme(Main)

const GlobalStyle = createGlobalStyle`
  body {
    // @ts-ignore
    background-color: ${({ theme }): Property.Color => theme.colors.panelBackground};
    font-size: 12px;
  }
  html, body {
     ${breakpoints.mobile`
       font-size: 12px;
    `}
  
    ${breakpoints.mobileLandscape`
       font-size: 13px;
    `}
  
    ${breakpoints.tablet`
      font-size: 15px;
    `}
  
    ${breakpoints.tabletLandscape`
      font-size: 15px;
    `}
    ${breakpoints.desktop`
      font-size: 17px;
    `}
  
    ${breakpoints.largeDesktop`
      font-size: 20px;
    `}
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

// eslint-disable-next-line react/jsx-props-no-spreading,@typescript-eslint/no-unused-vars
const GridContainer = styled(({ selectedStation, ...props }) => <Div100vh {...props} />)`
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: ${({ selectedStation }): Property.GridTemplateRows<string> => (selectedStation ? '5rem 1fr 8rem' : '5rem 1fr')};
  ${({ selectedStation }): FlattenSimpleInterpolation => breakpoints.tablet`
    grid-template-rows: ${selectedStation ? '5rem 1fr 7rem' : '5rem 1fr'};
  `};
  background-color: ${({ theme }): Property.Color => theme.colors.mainBackground};
`
