import React from 'react'
import { withTheme } from 'styled-components'

import { WithTheme, Icon } from 'src/types'

interface Props extends WithTheme, Icon {}

const PreviousIcon: React.FC<Props> = ({ theme, size = 1.75, fill = theme.colors.control }) => {
  const width = `${size}rem`
  const height = `${(20 / 28) * size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 28 20">
      <path
        d="M26 1.2l-10 6V1.9c0-.8-.5-1.1-1.2-.7L1.4 9.3c-.7.4-.7 1.1 0 1.5l13.4 8.1c.7.4 1.2.1 1.2-.7v-5.3l10 6c.7.4 1.2.1 1.2-.7V1.9c0-.8-.5-1.1-1.2-.7z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(PreviousIcon)
