import { DefaultTheme } from 'styled-components'
import light from './light'
import dark from './dark'

export interface Themes {
  light: DefaultTheme
  dark: DefaultTheme
}

export default {
  light,
  dark
} as Themes
