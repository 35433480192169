import { DefaultTheme } from 'styled-components'

import { IconState } from 'src/types'
import palette from './palette'
import { Colors } from './colors'

const gray950 = '#272822'
const gray900 = '#33342e'
const gray800 = '#303030'
const gray700 = '#424242'
const gray500 = '#4f4f4f'
const gray450 = '#575757'
const gray400 = '#646464'
const gray300 = '#7d7d7d'

const white900 = '#ececec'
const white600 = '#c8c8c8'
const white500 = '#b4b4b4'

const red = '#af0000'

const colors: Colors = {
  text: white600,
  mainBackground: gray950,
  panelBackground: gray800,
  selectedBackground: gray900,
  border: gray900,
  control: gray400,
  loading: gray700,
  logo: palette.primary,
  icon: gray400,
  station: {
    name: gray500,
    meta: white500,
    favoriteControl: {
      [IconState.normal]: gray300,
      [IconState.active]: red
    },
    historyIcon: palette.gray30
  },
  favoriteControl: {
    [IconState.normal]: gray500,
    [IconState.active]: red
  },
  themeControl: {
    [IconState.normal]: gray500,
    [IconState.active]: palette.yellow
  },
  filterControl: {
    [IconState.normal]: gray500,
    [IconState.active]: palette.primary
  },
  search: {
    input: palette.primary,
    selection: palette.white,
    background: gray700,
    placeholder: palette.primary,
    icon: palette.primary,
    clearControl: gray400
  },
  popover: {
    background: gray500,
    text: white600,
    hover: {
      background: gray450,
      text: white900
    }
  },
  image: {
    background: gray700,
    border: gray900
  }
}

export default {
  colors,
  station: {
    item: {
      image: {
        opacity: 0.65
      },
      selected: {
        image: {
          opacity: 0.85
        }
      }
    }
  }
} as DefaultTheme
