import React, { FC, useEffect, useCallback } from 'react'
import { Modal, Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, withTheme } from 'styled-components'

import { EVENT_CLOSE_MODALS } from 'src/constants'
import { WithTheme } from 'src/types'
import { dispatcher } from 'src/utils'

import { setHistoryStationId, selectHistoryStationId } from 'src/store/ui'

import StationHistory from '../station/StationHistory'

const HistoryModal: FC<WithTheme> = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const historyStationId = useSelector(selectHistoryStationId)

  const memoizedClose = useCallback((): void => {
    dispatch(setHistoryStationId(null))
  }, [])

  useEffect((): (() => void) => {
    dispatcher.on(EVENT_CLOSE_MODALS, memoizedClose)
    return (): void => {
      dispatcher.removeAllListeners(EVENT_CLOSE_MODALS)
    }
  }, [memoizedClose])

  return (
    <Modal open={!!historyStationId} onClose={memoizedClose} disableAutoFocus>
      <Box
        sx={{
          position: 'absolute',
          top: ['5%', '10%'],
          left: ['5%', '20%'],
          width: ['90%', '60%'],
          height: ['90%', '60%'],
          borderRadius: 1,
          backgroundColor: theme.colors.panelBackground,
          borderColor: theme.colors.border,
          overflow: 'hidden'
        }}
      >
        <StationHistory theme={theme} />
      </Box>
    </Modal>
  )
}

export default withTheme(HistoryModal)
