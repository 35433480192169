import { BrandConfig } from './model'

const brandConfig: BrandConfig = {
  BRAND_ID: 'zapp',
  BRAND_NAME: 'Zapp',
  APP_ID: 'fm.zapp',
  LOCALE: 'nl-NL',
  WEB_URL: 'https://zapp.fm',
  IMAGE_BASE_URL: 'https://static.zapp.fm/images/',
  SITE_TITLE: 'Zapp FM',
  GA_UA_ID: 'UA-73136876-1',
  FIREBASE_APP_ID: '1:72044832975:web:465c8334265ac143'
}

export default brandConfig
