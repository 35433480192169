import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IntlShape, useIntl } from 'react-intl'
import styled, { withTheme } from 'styled-components'
import { Property } from 'csstype'

import { EVENT_CLOSE_MODALS } from 'src/constants'
import { WithTheme } from 'src/types'
import { breakpoints } from 'src/styles'
import { ClearIcon, SearchIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'
import { FilterValue, selectFilter, setFilter } from 'src/store/user'

const height = 2.2
const radius = 0.5

const wideHeight = 1.875
const wideRadius = 1.875

const Container = styled.div``

const Search: React.FC<WithTheme> = ({ theme, className }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const filter = useSelector(selectFilter)

  if (filter === FilterValue.CLEAR_COMMAND) dispatch(setFilter(null))

  const searchValue =
    !filter || [FilterValue.FAVORITES, FilterValue.RECENTLY_PLAYED, FilterValue.MOST_PLAYED].includes(filter as FilterValue) ? '' : filter

  const placeHolderText = getPlaceHolderText(intl, filter)

  const clearFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(setFilter(null))
  }

  const onChange = (e: SyntheticEvent): void => {
    // @ts-ignore
    dispatch(setFilter(e.target.value ?? null))
  }

  return (
    <Container className={className}>
      <InputContainer>
        <Input onChange={onChange} value={searchValue} placeholder={placeHolderText} />
        <SearchIconContainer>
          <SearchIcon fill={theme.colors.search.icon} />
        </SearchIconContainer>
        <ClearButtonContainer onClick={clearFilter}>
          {!!searchValue && <ClearIcon fill={theme.colors.search.clearControl} />}
        </ClearButtonContainer>
      </InputContainer>
    </Container>
  )
}

export default withTheme(Search)

const InputContainer = styled.div`
  height: ${height}rem;
  ${breakpoints.tablet`
    height: ${wideHeight}rem;
  `};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin-top: -0.625rem;
`

const Input = styled.input`
  flex: 1;
  height: ${height}rem;
  border-radius: ${radius}rem;
  ${breakpoints.tablet`
    height: ${wideHeight}rem;
    border-radius: ${wideRadius}rem;
  `};
  color: ${({ theme }): Property.Color => theme.colors.search.input};
  border-width: 0;
  font-size: 1.3em;
  width: 20vw;
  ${breakpoints.tablet`
    font-size: 1.1em;
  `};
  background-color: ${({ theme }): Property.Color => theme.colors.search.background};
  padding: 0.3125rem 2.5rem;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }): Property.Color => theme.colors.search.placeholder};
  }
`

const SearchIconContainer = styled.div`
  width: ${height}rem;
  height: ${height}rem;
  ${breakpoints.tablet`
    width: ${wideHeight}rem;
    height: ${wideHeight}rem;
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0.5rem;
  top: 0.35rem;
`

const ClearButtonContainer = styled.div`
  width: ${height}rem;
  height: ${height}rem;
  ${breakpoints.tablet`
    width: ${wideHeight}rem;
    height: ${wideHeight}rem;
  `};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.35rem;
  cursor: pointer;
`

const getPlaceHolderText = (intl: IntlShape, filter: string | null): string => {
  switch (filter) {
    case FilterValue.FAVORITES:
      return intl.formatMessage({ id: 'filter.label.stations.favorites' })
    case FilterValue.RECENTLY_PLAYED:
      return intl.formatMessage({ id: 'filter.label.stations.recently_played' })
    case FilterValue.MOST_PLAYED:
      return intl.formatMessage({ id: 'filter.label.stations.most_played' })
    default:
      return intl.formatMessage({ id: 'filter.label.stations.all' })
  }
}
