import { PLATFORM } from 'src/config/platform'
import { db, DbRef } from 'src/firebase/db'
import { AppDispatch, AppThunk, State } from '../model'
import { setFetching } from '../ui/slice'

import { BootStrapKey, StationBase, MetaData, Features } from './model'
import { setBootStrapKey, setStations, setMetadata, setSubscribed, setFeatures } from './slice'
import { selectPath } from './selectors'

let bootStrapRef: DbRef
let featuresRef: DbRef
let stationRef: DbRef
let metaDataRef: DbRef

export const unsubscribe = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setSubscribed(false))
  if (bootStrapRef) bootStrapRef.off()
  if (featuresRef) featuresRef.off()
  if (stationRef) stationRef.off()
  if (metaDataRef) metaDataRef.off()
}

export const subscribeToMetaData = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  if (metaDataRef) metaDataRef.off()
  metaDataRef = db.ref(`/${selectPath(getState())}/metadata`)
  metaDataRef.on('value', (snapshot: { val: () => { [s: string]: MetaData } | ArrayLike<MetaData> }): void => {
    dispatch(setMetadata(Object.values(snapshot.val())))
  })
}

export const subscribeToFeatures = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  if (featuresRef) featuresRef.off()
  featuresRef = db.ref(`/${selectPath(getState())}/features/${PLATFORM}`)
  featuresRef.on('value', (snapshot: { val: () => Features }): void => {
    dispatch(setFeatures(snapshot.val()))
  })
}

export const onBootStrapKey = (bootStrapKey: BootStrapKey): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setBootStrapKey(bootStrapKey))
  dispatch(subscribeToFeatures())
  dispatch(fetchStations())
  dispatch(subscribeToMetaData())
}

export const fetchStations = (): AppThunk => async (dispatch: AppDispatch, getState: () => State) => {
  if (stationRef) stationRef.off()
  stationRef = db.ref(`/${selectPath(getState())}/stations`)
  stationRef.on(
    'value',
    (snapshot: { val: () => { [s: string]: unknown } | ArrayLike<unknown> }): void => {
      const data = Object.values(snapshot.val() as StationBase[]).sort((a, b): number => a.position - b.position)
      dispatch(setStations(data))
      dispatch(setFetching(false))
    },
    (): void => {
      dispatch(setFetching(false))
    }
  )
}

export const fetch = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setFetching(true))
  dispatch(setSubscribed(true))
  if (!bootStrapRef) bootStrapRef = db.ref(`/boot/${PLATFORM}`)
  bootStrapRef.on(
    'value',
    (snapshot: { val: () => BootStrapKey }): void => {
      dispatch(onBootStrapKey(snapshot.val()))
    },
    (): void => {
      dispatch(setFetching(false))
    }
  )
}

export const refresh = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(unsubscribe())
  dispatch(fetch())
}
