import brandConfig from 'src/config/brands'

const { IMAGE_BASE_URL, SITE_TITLE, GA_UA_ID, LOCALE } = brandConfig

export const THUMBNAIL_BASE_URL = `${IMAGE_BASE_URL}210x120`
export const GRID_THUMB_BASE_URL = `${IMAGE_BASE_URL}400x225`

export const HANDLE_STATISTICS_DELAY = 2000

export const MIN_FILTER_VALUE_LENGTH = 3

export const TEXT_SITE_TITLE = SITE_TITLE

export const GA_UA_IDENTIFIER = GA_UA_ID

export const EVENT_CLOSE_MODALS = '@EVENT_CLOSE_MODALS'

export const APP_SET_PLAY = '@APP_SET_PLAY'
export const APP_TOGGLE_PLAY = '@APP_TOGGLE_PLAY'
export const APP_STATION_NEXT = '@APP_STATION_NEXT'
export const APP_STATION_PREV = '@APP_STATION_PREV'

export const SPOTIFY_SEARCH_URL = '//open.spotify.com/search/'

export const INTL_LOCALE = LOCALE
