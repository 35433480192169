import React from 'react'
import { useSelector } from 'react-redux'

import { TEXT_SITE_TITLE } from 'src/constants'
import { selectSelectedStation } from 'src/store/data'

const HtmlHead: React.FC = () => {
  const selectedStation = useSelector(selectSelectedStation)

  let title: string
  if (selectedStation) {
    const { name, metadata } = selectedStation
    title = (metadata && metadata.title) || name
  } else {
    title = TEXT_SITE_TITLE
  }

  document.title = title

  return null
}

export default HtmlHead
