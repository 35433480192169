import React from 'react'
import { useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Property } from 'csstype'

import { WithTheme } from 'src/types'
import { breakpoints } from 'src/styles'
import { selectFilteredStations } from 'src/store/data'
import { FilterValue, selectFilter } from 'src/store/user'

import StationGridItem from './StationGridItem'

const StationGrid: React.FC<WithTheme> = () => {
  let stations = useSelector(selectFilteredStations)
  const filter = useSelector(selectFilter)

  if ([FilterValue.MOST_PLAYED, FilterValue.RECENTLY_PLAYED].includes(filter as FilterValue)) {
    stations = stations.slice(0, 12)
  }
  return (
    <Grid>
      {stations.map(
        (item): React.ReactNode => (
          <StationGridItem key={item.id} station={item} selected={item.selected} />
        )
      )}
    </Grid>
  )
}

export default withTheme(StationGrid)

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-row-gap: 1px;
  // grid-column-gap: 1px;
  // background-color: ${({ theme }): Property.Color => theme.colors.border};
  background-color: ${({ theme }): Property.Color => theme.colors.mainBackground};

  ${breakpoints.mobile`
     grid-template-columns: repeat(2, 1fr);
  `};

  ${breakpoints.mobileLandscape`
     grid-template-columns: repeat(3, 1fr);
  `};

  ${breakpoints.tablet`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${breakpoints.tabletLandscape`
    grid-template-columns: repeat(4, 1fr);
  `};
  ${breakpoints.desktop`
    grid-template-columns: repeat(5, 1fr);
  `};

  ${breakpoints.largeDesktop`
    grid-template-columns: repeat(7, 1fr);
  `};
`
