import { useRef, useEffect } from 'react'

export default <T>(value: T): T | undefined => {
  const ref = useRef<T>()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  return ref.current
}
