import _uniq from 'lodash.uniq'
import { StationBase } from 'src/store/data/model'
import { MIN_FILTER_VALUE_LENGTH } from 'src/constants'

// eslint-disable-next-line import/prefer-default-export
export const isMatchForSearchValue = (s: StationBase, searchValue: string | null): boolean => {
  if (!searchValue) return false
  if (searchValue.length < MIN_FILTER_VALUE_LENGTH) return true
  // TODO: Fully refactor, currently this is an unscored, unsorted, non-prioritized search method
  const fullText = _uniq([s.name, s.slug, ...s.tags])
    .map((value): string => value.toLowerCase())
    .join(',')
  return fullText.includes(searchValue.toLowerCase())
}
