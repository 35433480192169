import React from 'react'
import { withTheme } from 'styled-components'

import { WithTheme, Icon } from 'src/types'

interface Props extends WithTheme, Icon {}

const PlaylistIcon: React.FC<Props> = ({ theme, size = 1.55, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <circle fill={fill} cx={5775.5} cy={5645.5} r={44.5} />
      <path fill={fill} d="M5904.5 5646h260.8" />
      <circle fill={fill} cx={5775.5} cy={5766.5} r={44.5} />
      <path fill={fill} d="M5904.5 5767h260.8" />
      <circle fill={fill} cx={5775.5} cy={5887.5} r={44.5} />
      <path fill={fill} d="M5904.5 5888h260.8" />
      <circle fill={fill} cx={3.6} cy={4.7} r={1.6} />
      <path fill={fill} d="M16.5 6.3H7.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
      <circle fill={fill} cx={3.6} cy={10} r={1.6} />
      <path fill={fill} d="M16.5 11.6H7.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.3c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z" />
      <circle fill={fill} cx={3.6} cy={15.3} r={1.6} />
      <path fill={fill} d="M16.5 16.8H7.2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h9.3c.8 0 1.5.7 1.5 1.5 0 .9-.7 1.5-1.5 1.5z" />
      <circle fill={fill} cx={5775.5} cy={5645.5} r={44.5} />
      <path fill={fill} d="M5904.5 5646h260.8" />
      <circle fill={fill} cx={5775.5} cy={5766.5} r={44.5} />
      <path fill={fill} d="M5904.5 5767h260.8" />
      <circle fill={fill} cx={5775.5} cy={5887.5} r={44.5} />
      <path fill={fill} d="M5904.5 5888h260.8" />
    </svg>
  )
}

export default withTheme(PlaylistIcon)
