import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import SmartBanner from 'react-smartbanner'
import 'react-smartbanner/dist/main.css'

import IntlProvider from 'src/i18n/IntlProvider'
import brandConfig from 'src/config/brands'

import { selectTheme } from 'src/store/user/selectors'

import SlugHandler from 'src/utils/SlugHandler'
import ElectronHandler from 'src/utils/ElectronHandler'

import themes from 'src/styles/themes'
import AudioStream from 'src/components/media/AudioStream'
import { HtmlHead, Main, Header, Body, Footer } from 'src/components/layout'
import { fetch } from './store/data'

const { SITE_TITLE } = brandConfig

const App: React.FC = () => {
  const dispatch = useDispatch()
  const themeName = useSelector(selectTheme)
  const theme = themes[themeName]

  useEffect(() => {
    dispatch(fetch())
  }, [])

  return (
    <IntlProvider>
      <ThemeProvider theme={theme}>
        <HashRouter hashType="noslash">
          <Switch>
            <Route path="/:slug?">
              <SmartBanner title={SITE_TITLE} author="Why The Face B.V." />
              <HtmlHead />
              <Main>
                <Header />
                <Body />
                <Footer />
                <AudioStream />
              </Main>
              <SlugHandler />
            </Route>
          </Switch>
        </HashRouter>
        <ElectronHandler />
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App
