import en from './languages/en'
import nl from './languages/nl'
import de from './languages/de'

import { Translations } from './model'

export default {
  en,
  nl,
  de
} as Record<string, Translations>
