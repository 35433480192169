import React, { FC, SyntheticEvent, useState, useEffect, useCallback, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import Popover from 'react-tiny-popover'

import { EVENT_CLOSE_MODALS } from 'src/constants'
import { IconState, WithTheme } from 'src/types'
import { dispatcher } from 'src/utils'

import { FilterValue, selectFilter, setFilter } from 'src/store/user'

import { OptionsRightIcon } from 'src/components/icons'
import FilterOptions from './FilterOptions'

const FilterControl: FC<WithTheme> = ({ className }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const memoizedClose = useCallback((): void => {
    setIsOpen(false)
  }, [setIsOpen])

  const memoizedOnOptionClick = useCallback(
    (filterValue: string | null): void => {
      dispatch(setFilter(filterValue))
      setIsOpen(false)
    },
    [setFilter, setIsOpen]
  )

  useEffect((): (() => void) => {
    dispatcher.on(EVENT_CLOSE_MODALS, memoizedClose)
    return (): void => {
      dispatcher.removeAllListeners(EVENT_CLOSE_MODALS)
    }
  }, [memoizedClose])

  const active = [FilterValue.MOST_PLAYED, FilterValue.RECENTLY_PLAYED].includes((filter as FilterValue) || '')
  const iconFill = theme.colors.filterControl[IconState[active ? 'active' : 'normal']]

  const toggleFilterOptions = (e: SyntheticEvent): void => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <Popover
      isOpen={isOpen}
      position={['bottom', 'right', 'left']}
      padding={10}
      onClickOutside={memoizedClose}
      content={(): ReactElement => <FilterOptions filter={filter} onOptionClick={memoizedOnOptionClick} theme={theme} />}
    >
      <Container className={className} onClick={toggleFilterOptions}>
        <OptionsRightIcon fill={iconFill} />
      </Container>
    </Popover>
  )
}

export default FilterControl

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
`
