import React, { SyntheticEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import { IconState, WithTheme } from 'src/types'
import { EVENT_CLOSE_MODALS } from 'src/constants'
import { MoonIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

import { selectTheme, toggleTheme } from 'src/store/user'

const ThemeControl: React.FC<WithTheme> = ({ className }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const themeName = useSelector(selectTheme)
  const dark = themeName === 'dark'
  const iconFill = theme.colors.themeControl[IconState[dark ? 'active' : 'normal']]

  const onClick = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toggleTheme())
  }

  return (
    <Container className={className} onClick={onClick}>
      <MoonIcon fill={iconFill} />
    </Container>
  )
}

export default ThemeControl

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
