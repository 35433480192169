import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { DefaultTheme, withTheme } from 'styled-components'

import { HistoryItem, selectPath, selectStationHistoryStation } from 'src/store/data'
import { Property } from 'csstype'
import Truncate from 'react-truncate'
import { useObjectVal } from 'react-firebase-hooks/database'

import { dimensions } from '../../styles'
import { INTL_LOCALE, SPOTIFY_SEARCH_URL, THUMBNAIL_BASE_URL } from '../../constants'
import { CloseIcon, LoadingIcon, PlaylistIcon } from '../icons'
import { setHistoryStationId, setPlaying, setStation } from '../../store/ui'
import { db } from '../../firebase/db'

interface Props {
  theme: DefaultTheme
}

const StationHistory: React.FC<Props> = ({ theme }) => {
  const dispatch = useDispatch()
  const station = useSelector(selectStationHistoryStation)
  const key = useSelector(selectPath)
  const [items, loading, error] = useObjectVal<HistoryItem[]>(db.ref(`/${key}/history/${station?.id}/items`))

  const onClose = () => {
    dispatch(setHistoryStationId(null))
  }

  if (!station) return null

  const { id, name, image } = station
  const imageUri = `${THUMBNAIL_BASE_URL}/${image}`

  const onClickStation = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatch(setStation(id))
    dispatch(setPlaying(true))
  }

  return (
    <Container>
      <Heading>
        <StationImage src={imageUri} alt={name} onClick={onClickStation} />
        <HeadingIcon>
          <PlaylistIcon fill={theme.colors.text} />
        </HeadingIcon>
        <StationTitle>
          <Truncate>{name}</Truncate>{' '}
        </StationTitle>
        <CloseControl onClick={onClose}>
          <CloseIcon />
        </CloseControl>
      </Heading>
      <Body>
        {loading && (
          <Loading>
            <LoadingIcon fill={theme.colors.icon} />
          </Loading>
        )}
        {!loading && !items && <NoData>Geen gegevens beschikbaar{error ? ` - ${error}` : ''}</NoData>}
        {!loading && items && (
          <>
            {items.map(({ start, title }) => (
              <Row>
                <Time>{new Intl.DateTimeFormat(INTL_LOCALE, { hour: 'numeric', minute: 'numeric' }).format(new Date(start))}</Time>
                <Title>
                  <a rel="noreferrer" href={`${SPOTIFY_SEARCH_URL}${encodeURIComponent(title)}`} target="_blank">
                    {title}
                  </a>
                </Title>
              </Row>
            ))}
          </>
        )}
      </Body>
    </Container>
  )
}

export default withTheme(StationHistory)

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme }): Property.Color => theme.colors.text};
`

const Body = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 4.7rem ${dimensions.paddingHorizontal}rem 0.7rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-template-areas: 'time title';
  grid-column-gap: 1rem;
  margin-bottom: 0.7rem;
`

const Time = styled.div`
  grid-area: time;
  justify-self: end;
`

const Title = styled.div`
  grid-area: title;
  justify-self: start;
  a {
    text-decoration: none;
    color: ${({ theme }): Property.Color => theme.colors.text};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Heading = styled.div`
  max-height: 3rem;
  font-weight: bold;
  z-index: 2;
  padding: 0.7rem ${dimensions.paddingHorizontal}rem;
  background-color: ${({ theme }): Property.Color => theme.colors.panelBackground};
  border-bottom-color: ${({ theme }): Property.Color => theme.colors.icon};
  border-bottom-width: 1px;
  border-bottom-style: solid;

  display: grid;
  grid-template-columns: 5rem 2rem 1fr 2rem;
  grid-template-areas: 'image icon title close';
  align-items: center;
`

const StationTitle = styled.div`
  grid-area: title;
`

const HeadingIcon = styled.div`
  grid-area: icon;
  margin-top: 0.3rem;
  opacity: 0.8;
`

const CloseControl = styled.div`
  margin-top: 0.3rem;
  grid-area: close;
  cursor: pointer;
`

const StationImage = styled.img`
  grid-area: image;
  width: 4.375rem;
  height: 2.5rem;
  background-color: ${({ theme }): Property.Color => theme.colors.image.background};
  border-width: 1px;
  border-color: ${({ theme }): Property.Color => theme.colors.image.border};
  border-style: solid;
  cursor: pointer;
`

const NoData = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

const Loading = styled.div`
  margin-top: 1.4rem;
  text-align: center;
`
