import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { Property } from 'csstype'
import Truncate from 'react-truncate'

import { IconState, WithTheme } from 'src/types'
import { EVENT_CLOSE_MODALS, SPOTIFY_SEARCH_URL, THUMBNAIL_BASE_URL } from 'src/constants'
import { breakpoints, dimensions } from 'src/styles'
import { FavoriteIcon, PlaylistIcon } from 'src/components/icons'
import { dispatcher } from 'src/utils'

import { selectFeatureHistory, Station } from 'src/store/data'
import { toggleFavorite } from 'src/store/user'
import { setHistoryStationId } from '../../store/ui'

interface Props extends WithTheme {
  station?: Station
}

const StationNowPlaying: React.FC<Props> = ({ station, className, theme }) => {
  const dispatch = useDispatch()
  const featureHistoryEnabled = useSelector(selectFeatureHistory)

  if (!station) return null

  const { id, name, image, favorite, metadata } = station
  const imageUri = `${THUMBNAIL_BASE_URL}/${image}`

  const iconState = favorite ? IconState.active : IconState.normal

  const favoriteColor = theme.colors.favoriteControl[iconState]

  const onClick = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toggleFavorite(id))
  }

  const onClickHistory = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(setHistoryStationId(id))
  }

  return (
    <GridContainer className={className}>
      <StationImage src={imageUri} alt={name} />
      <TextContainer>
        <Name>
          <Truncate>{name}</Truncate>
          {featureHistoryEnabled && (
            <HistoryControl onClick={onClickHistory}>
              <PlaylistIcon size={1} />
            </HistoryControl>
          )}
        </Name>
        {metadata && (
          <SpotifyLink rel="noreferrer" href={`${SPOTIFY_SEARCH_URL}${encodeURIComponent(metadata.title)}`} target="_blank">
            <Meta>
              <Truncate>{metadata.title}</Truncate>
            </Meta>
          </SpotifyLink>
        )}
      </TextContainer>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <FavoriteControl onClick={onClick}>
        <FavoriteIcon fill={favoriteColor} />
      </FavoriteControl>
    </GridContainer>
  )
}

export default withTheme(StationNowPlaying)

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 3rem;
  grid-template-areas: 'image text favorite-toggle';
  ${breakpoints.tablet`
    grid-template-columns: 10.5rem 1.5rem 1fr;
    grid-template-areas: 'image favorite-toggle text';
  `};
  padding: ${dimensions.paddingHorizontal}rem 0;
  user-select: none;
`

const StationImage = styled.img`
  grid-area: image;
  margin-left: ${dimensions.paddingHorizontal}rem;
  margin-right: ${dimensions.paddingHorizontal}rem;
  width: 4.375rem;
  height: 2.5rem;
  ${breakpoints.tablet`
    width: 8.75rem;
    height: 5rem;
  `};
  background-color: ${({ theme }): Property.Color => theme.colors.image.background};
  border-width: 1px;
  border-color: ${({ theme }): Property.Color => theme.colors.image.border};
  border-style: solid;
  opacity: ${({ theme }): Property.Opacity => theme.station.item.selected.image.opacity};
`

const FavoriteControl = styled.div`
  grid-area: favorite-toggle;
  padding-right: ${dimensions.paddingHorizontal}rem;
  ${breakpoints.tablet`
    padding-right: 0;
  `};
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  ${breakpoints.tablet`
    align-items: flex-start;
    justify-content: center;
  `};
  cursor: pointer;
`

const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const Name = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }): Property.Color => theme.colors.text};
`

const Meta = styled.div`
  font-size: 1rem;
  color: ${({ theme }): Property.Color => theme.colors.text};
`
const SpotifyLink = styled.a`
    text-decoration: none;
    color: ${({ theme }): Property.Color => theme.colors.text};
    &:hover {
      text-decoration: underline;
    }
  }
`

const HistoryControl = styled.span`
  position: relative;
  top: 0.18rem;
  margin: 0 0.3rem;
  padding: 0;
  cursor: pointer;
`
