import React from 'react'
import { withTheme } from 'styled-components'

import { Icon, WithTheme } from 'src/types'

interface Props extends WithTheme, Icon {}

const ClearIcon: React.FC<Props> = ({ theme, size = 1.125, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 612 612">
      <path
        d="m561 76.5h-382.5c-17.85 0-30.6 7.65-40.8 22.95l-137.7 206.55 137.7 206.55c10.2 12.75 22.95 22.95 40.8 22.95h382.5c28.05 0 51-22.95 51-51v-357c0-28.05-22.95-51-51-51m-76.5 321.3l-35.7 35.7-91.8-91.8-91.8 91.8-35.7-35.7 91.8-91.8-91.8-91.8 35.7-35.7 91.8 91.8 91.8-91.8 35.7 35.7-91.8 91.8 91.8 91.8"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(ClearIcon)
