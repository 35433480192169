import React from 'react'
import { withTheme } from 'styled-components'

import { WithTheme, Icon } from 'src/types'

interface Props extends WithTheme, Icon {}

const CloseIcon: React.FC<Props> = ({ theme, size = 1.55, fill = theme.colors.icon }) => {
  const width = `${size}rem`
  const height = `${size}rem`

  return (
    <svg width={width} height={height} viewBox="0 0 20 20">
      <circle fill={fill} cx={5775.5} cy={5645.5} r={44.5} />
      <path fill={fill} d="M5904.5 5646h260.8" />
      <circle fill={fill} cx={5775.5} cy={5766.5} r={44.5} />
      <path fill={fill} d="M5904.5 5767h260.8" />
      <circle fill={fill} cx={5775.5} cy={5887.5} r={44.5} />
      <path fill={fill} d="M5904.5 5888h260.8" />
      <circle fill={fill} cx={5775.5} cy={5645.5} r={44.5} />
      <path fill={fill} d="M5904.5 5646h260.8" />
      <circle fill={fill} cx={5775.5} cy={5766.5} r={44.5} />
      <path fill={fill} d="M5904.5 5767h260.8" />
      <circle fill={fill} cx={5775.5} cy={5887.5} r={44.5} />
      <path fill={fill} d="M5904.5 5888h260.8" />
      <path
        fill={fill}
        d="m10.7 10 4.9-4.9c.2-.2.2-.5 0-.7s-.5-.2-.7 0L10 9.3 5.1 4.5c-.1-.2-.4-.2-.6 0s-.2.5 0 .6L9.3 10l-4.9 4.9c-.2.2-.2.5 0 .7.1.1.2.1.3.1.1 0 .2 0 .3-.1l4.9-4.9 4.9 4.9c.1.1.2.1.3.1.1 0 .2 0 .3-.1.2-.2.2-.5 0-.7L10.7 10z"
      />
    </svg>
  )
}

export default withTheme(CloseIcon)
