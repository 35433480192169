import React, { SyntheticEvent, FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { withTheme } from 'styled-components'

import { EVENT_CLOSE_MODALS } from 'src/constants'
import { IconState, WithTheme } from 'src/types'
import { dispatcher } from 'src/utils'

import { FavoriteIcon } from 'src/components/icons'
import { selectFilter, toggleFavoritesFilter, FilterValue } from 'src/store/user'

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
`

const FavoriteControl: FC<WithTheme> = ({ className, theme }) => {
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)

  const active = filter === FilterValue.FAVORITES
  const iconFill = theme.colors.favoriteControl[IconState[active ? 'active' : 'normal']]

  const toggleFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toggleFavoritesFilter())
  }

  return (
    <Container className={className} onClick={toggleFilter}>
      <FavoriteIcon fill={iconFill} />
    </Container>
  )
}

export default withTheme(FavoriteControl)
