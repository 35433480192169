export interface Dimensions {
  paddingHorizontal: number
  iconSize: number
}

const dimensions: Dimensions = {
  paddingHorizontal: 1,
  iconSize: 2
}

export default dimensions
