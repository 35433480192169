import { Translations } from '../model'

export default {
  'filter.options.title': 'Filters',
  'filter.label.stations.all': 'Alle Stations',
  'filter.label.stations.favorites': 'Mijn Favorieten',
  'filter.label.stations.recently_played': 'Recent beluisterd',
  'filter.label.stations.most_played': 'Meest beluisterd',

  'button.label.cancel': 'Annuleren',
  'button.label.close': 'Sluiten',

  'theme.options.title': 'Weergave',
  'theme.label.default': 'Standaard',
  'theme.label.dark': 'Donker',
  'theme.label.appearanceFollowOS': 'Volg toestel instelling'
} as Translations
