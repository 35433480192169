import dotenv from 'dotenv'

import { BrandConfig } from './model'
import swapp from './swapp'
import zapp from './zapp'
import zpy from './zpy'
import zrad from './zrad'

dotenv.config()

const brandConfig = ((): BrandConfig => {
  switch (process.env.REACT_APP_BRAND_ID) {
    case 'swapp':
      return swapp
    case 'zpy':
      return zpy
    case 'zrad':
      return zrad
    case 'zapp':
    default:
      return zapp
  }
})()

export default brandConfig
