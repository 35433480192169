import { DefaultTheme } from 'styled-components'
import { Property } from 'csstype'

/* eslint-disable no-shadow */

export enum ColorFill {
  'default',
  'inverted'
}

export interface Icon {
  size?: number
  fill?: Property.Color
}

export enum IconState {
  normal,
  active
}

export interface WithTheme {
  className?: string
  theme: DefaultTheme
}

export enum AnalyticsCategory {
  DEFAULT = 'default',
  PLAYBACK = 'playback'
}

export enum AnalyticsEvent {
  PLAY = 'play'
}
