import { Statistics } from '../data/model'

/* eslint-disable no-shadow */

export type PoS = 'nl' | 'de'

export type Locale = 'en' | 'nl' | 'de'

export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum FilterValue {
  FAVORITES = ':favorites',
  RECENTLY_PLAYED = ':recent',
  MOST_PLAYED = ':most',
  CLEAR_COMMAND = ':reset'
}

export enum DisplayMode {
  LIST = 1,
  GRID1 = 2,
  GRID2 = 3
}

export interface UserState {
  pos: PoS
  locale: Locale
  theme: ThemeName
  appearanceFollowOS: boolean
  displayMode: DisplayMode
  selectedStationId: string | null
  filter: FilterValue | string | null
  statistics: Record<string, Statistics>
  favoriteStationIds: string[]
}
