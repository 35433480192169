import brandConfig from 'src/config/brands'

const { FIREBASE_APP_ID } = brandConfig

export default {
  apiKey: 'AIzaSyCIcPXdsU20tbSY6PeW0tn3zDi_0D5gf3E',
  authDomain: 'zapp-242609.firebaseapp.com',
  databaseURL: 'https://zapp-242609.firebaseio.com',
  projectId: 'zapp-242609',
  storageBucket: 'zapp-242609.appspot.com',
  messagingSenderId: '72044832975',
  appId: FIREBASE_APP_ID
}
