import React, { SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'

import { WithTheme } from 'src/types'
import { dispatcher } from 'src/utils'
import { EVENT_CLOSE_MODALS } from 'src/constants'

import { selectSelectedStation } from 'src/store/data'
import { selectPlaying, selectLoading, togglePlayback, toNextStation, toPreviousStation } from 'src/store/ui'

import { PreviousIcon, PlayIcon, LoadingIcon, StopIcon, NextIcon } from 'src/components/icons'

const AudioControls: React.FC<WithTheme> = ({ className }) => {
  const dispatch = useDispatch()
  const selectedStation = useSelector(selectSelectedStation)
  const isPlay = useSelector(selectPlaying)
  const isLoading = useSelector(selectLoading)

  if (!selectedStation) return <></>

  const togglePlay = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(togglePlayback())
  }

  const setPrevStation = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toPreviousStation())
  }

  const setNextStation = (e: SyntheticEvent): void => {
    e.stopPropagation()
    dispatcher.emit(EVENT_CLOSE_MODALS)
    dispatch(toNextStation())
  }

  return (
    <GridContainer className={className}>
      <PreviousControl onClick={setPrevStation}>
        <PreviousIcon />
      </PreviousControl>
      {/* eslint-disable-next-line no-nested-ternary */}
      <PlaybackControl onClick={togglePlay}>{isPlay ? isLoading ? <LoadingIcon /> : <StopIcon /> : <PlayIcon />}</PlaybackControl>
      <NextControl onClick={setNextStation}>
        <NextIcon />
      </NextControl>
    </GridContainer>
  )
}

export default withTheme(AudioControls)

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'previous playback next';
`

const PreviousControl = styled.div`
  grid-area: previous;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PlaybackControl = styled.div`
  grid-area: playback;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const NextControl = styled.div`
  grid-area: next;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
