import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import usePrevious from 'src/utils/hooks/usePrevious'

import { selectStations } from 'src/store/data/selectors'

import { setStation } from 'src/store/ui/thunks'
import { selectFetching } from 'src/store/ui/selectors'

import { selectSelectedStationId } from 'src/store/user/selectors'

const SlugHandler: React.FC = () => {
  const dispatch = useDispatch()
  const { slug: stationSlug } = useParams<{ slug?: string }>()
  const selectedStationId = useSelector(selectSelectedStationId)
  const stations = useSelector(selectStations)
  const fetching = useSelector(selectFetching)

  const previousFetching = usePrevious<boolean>(fetching)

  // Update slug after stations change
  useEffect(() => {
    const slug = stations.find(s => s.id === selectedStationId)?.slug ?? ''
    window.location.hash = slug
  }, [stations, selectedStationId])

  // Based on provided slug set station
  useEffect(() => {
    if (previousFetching && !fetching && stations.length && stationSlug) {
      const requestedStation = stations.find(({ slug }) => slug === stationSlug)
      if (requestedStation) dispatch(setStation(requestedStation.id))
    }

    // eslint-disable-next-line
  }, [fetching, previousFetching, stations, stationSlug, setStation, dispatch])

  return null
}

export default SlugHandler
