import { createSelector, Selector } from '@reduxjs/toolkit'

import { State } from 'src/store/model'
import { UserState, PoS, Locale, ThemeName, FilterValue, DisplayMode } from './model'
import { Statistics } from '../data/model'

export const selectUserSlice: Selector<State, UserState> = (state: State) => state.user

export const selectPointOfSale: Selector<State, PoS> = createSelector(selectUserSlice, state => state.pos)

export const selectLocale: Selector<State, Locale> = createSelector(selectUserSlice, state => state.locale)

export const selectTheme: Selector<State, ThemeName> = createSelector(selectUserSlice, state => state.theme)

export const selectAppearanceFollowOS: Selector<State, boolean> = createSelector(selectUserSlice, state => state.appearanceFollowOS)

export const selectDisplayMode: Selector<State, DisplayMode> = createSelector(selectUserSlice, state => state.displayMode)

export const selectSelectedStationId: Selector<State, string | null> = createSelector(selectUserSlice, state => state.selectedStationId)

export const selectFilter: Selector<State, FilterValue | string | null> = createSelector(selectUserSlice, state => state.filter)

export const selectFavoriteStationIds: Selector<State, string[]> = createSelector(selectUserSlice, state => state.favoriteStationIds)

export const selectStatistics: Selector<State, Record<string, Statistics>> = createSelector(selectUserSlice, state => state.statistics)
