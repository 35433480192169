/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import config from './config'

export type DbRef = firebase.database.Reference | null

firebase.initializeApp(config)

// firebase.database.enableLogging(true)

export const db = firebase.database()
