import React from 'react'
import styled, { withTheme } from 'styled-components'

import { WithTheme } from 'src/types'
import StationGrid from 'src/components/station/StationGrid'

const Body: React.FC<WithTheme> = ({ className }) => (
  <Container>
    <StationGrid className={className} />
  </Container>
)

export default withTheme(Body)

const Container = styled.div`
  grid-area: body;
  overflow-y: scroll;
`
