import React, { SyntheticEvent } from 'react'
import { useIntl } from 'react-intl'
import styled, { DefaultTheme } from 'styled-components'
import { Property } from 'csstype'

import { FilterValue } from 'src/store/user'
import { breakpoints } from 'src/styles'
import FilterOption from './FilterOption'

interface Props {
  theme: DefaultTheme
  filter: string | null
  onOptionClick: (filterValue: string | null) => void
}

const FilterOptions: React.FC<Props> = ({ theme, filter, onOptionClick }) => {
  const intl = useIntl()
  const setFilterMostPlayed = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(FilterValue.MOST_PLAYED)
  }

  const setFilterRecentlyPlayed = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(FilterValue.RECENTLY_PLAYED)
  }

  const clearFilter = (e: SyntheticEvent): void => {
    e.stopPropagation()
    onOptionClick(null)
  }

  return (
    <Container theme={theme}>
      <FilterOption
        label={intl.formatMessage({ id: 'filter.label.stations.recently_played' })}
        active={filter === FilterValue.RECENTLY_PLAYED}
        onClick={setFilterRecentlyPlayed}
        theme={theme}
      />
      <FilterOption
        label={intl.formatMessage({ id: 'filter.label.stations.most_played' })}
        active={filter === FilterValue.MOST_PLAYED}
        onClick={setFilterMostPlayed}
        theme={theme}
      />
      <Divider theme={theme} />
      <FilterOption
        label={intl.formatMessage({ id: 'filter.label.stations.all' })}
        active={filter === null}
        onClick={clearFilter}
        theme={theme}
      />
    </Container>
  )
}

export default FilterOptions

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: ${({ theme }): Property.Color => theme.colors.popover.background};
  border-color: ${({ theme }): Property.Color => theme.colors.border};
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  font-size: 1.3rem;
  margin-top: 0.4rem;
  ${breakpoints.tablet`
    font-size: 1rem;
    margin-top: 0.6rem;
  `};
`

const Divider = styled.div`
  flex: 1;
  padding: 0;
  border-top: 1px solid ${({ theme }): Property.Color => theme.colors.border};
  margin: 0.1rem 0;
`
