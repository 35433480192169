const palette = {
  primary: '#379DD7',
  primaryLight: '#b1e3f7',
  primaryDark: '#287099',
  secondary: '#2d9cdb',
  green: '#53f493',
  positive: '#45AB36',
  negative: '#BB0000',
  offWhite: '#F2F2F2',
  red: '#ff0000',
  white: '#ffffff',
  gray12: '#f2f3f4',
  gray15: '#f7f7f7',
  gray20: '#e4e4e6',
  gray30: '#D4D4D4',
  gray45: '#b2b2b2',
  gray50: '#8e8e92',
  gray70: '#5a5a5e',
  black: '#000000',
  nearlyBlack: '#1c1e21',
  yellow: '#f4eb28',
  darkYellow: '#F4C22A'
}

export default palette
