import { createSelector, Selector } from '@reduxjs/toolkit'

import { State } from 'src/store/model'
import { UiState } from './model'

export const selectUiSlice: Selector<State, UiState> = (state: State) => state.ui

export const selectFetching: Selector<State, boolean> = createSelector(selectUiSlice, state => state.fetching)

export const selectPlaying: Selector<State, boolean> = createSelector(selectUiSlice, state => state.playing)

export const selectLoading: Selector<State, boolean> = createSelector(selectUiSlice, state => state.loading)

export const selectHistoryStationId: Selector<State, string | null> = createSelector(selectUiSlice, state => state.historyStationId)
