import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Property } from 'csstype'

import { WithTheme } from 'src/types'
import { breakpoints, dimensions } from 'src/styles'
import brandConfig from 'src/config/brands'
import Logo from 'src/components/layout/Logo'
import { FavoriteControl, FilterControl, ThemeControl } from 'src/components/controls'

import Search from './Search'

const { BRAND_ID } = brandConfig

const controlSize = 1.875
let logoSize = 4.15

switch (BRAND_ID) {
  case 'swapp':
    logoSize = 8
    break
  case 'zpy':
    logoSize = 3.1
    break
  case 'zrad':
    logoSize = 6
    break
  case 'zapp':
  default:
    logoSize = 4.15
}

const Header: React.FC<WithTheme> = () => (
  <GridContainer>
    <LogoContainer>
      <Logo size={logoSize} />
    </LogoContainer>
    <StyledSearch />
    <StyledFilterControl />
    <StyledThemeControl />
    <StyledFavoriteControl />
  </GridContainer>
)

export default withTheme(Header)

const GridContainer = styled.div`
  grid-area: header;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 ${dimensions.paddingHorizontal}rem;
  display: grid;
  grid-template-columns: ${controlSize}rem ${controlSize}rem 1fr ${controlSize}rem ${controlSize}rem;
  grid-template-areas: 'theme-control . search filter-control favorite-control';
  ${breakpoints.tablet`
    grid-template-columns: 12rem 1fr ${12 - 3 * controlSize}rem  ${controlSize}rem ${controlSize}rem ${controlSize}rem;
    grid-template-areas: 'logo search filter-control theme-control . favorite-control';
  `};
  background-color: ${({ theme }): Property.Color => theme.colors.panelBackground};
  border-bottom-color: ${({ theme }): Property.Color => theme.colors.border};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

const LogoContainer = styled.div`
  grid-area: logo;
  align-items: center;
  justify-content: center;
  display: none;
  margin-top: 0.25rem;
  ${breakpoints.tablet`
    width: ${logoSize}rem;
    display: flex;
  `};
`

const StyledSearch = styled(Search)`
  grid-area: search;
`

const StyledFilterControl = styled(FilterControl)`
  grid-area: filter-control;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${controlSize}rem;
`

const StyledThemeControl = styled(ThemeControl)`
  grid-area: theme-control;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${controlSize}rem;
`

const StyledFavoriteControl = styled(FavoriteControl)`
  grid-area: favorite-control;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: ${controlSize}rem;
`
